import React from 'react'
import Header from '../sections/header'
import Footer from '../sections/footer'
import { PerionicaSlika, Pranje, Pranje2, Pranje3 } from '../assets/images/images'
import NewHeader from '../sections/newHeader'

const Perionica = () => {
    return (
        <>
            <div className='container'>
                <NewHeader />
            </div>
            <div className='breadcrumbs'>
                <a href="/">Početna</a>
                <div style={{ paddingLeft: '.5rem', paddingRight: '.5rem' }}>/</div>
                <div>Perionica</div>
            </div>
            <div className='container' style={{ padding: '0rem 1rem', textAlign: 'center', boxSizing: 'border-box' }}>
                <h1>Uslužno pranje automobila</h1>
                <img src={PerionicaSlika} alt="Perionica Slika" style={{ width: '100%' }} />
                <h2 style={{ textAlign: 'left', fontSize: '2.3em', paddingLeft: '1rem' }}>Usluge: </h2>
                <div className='pranjevelikiflex'>
                    <div className='pranjeflex'>
                        <img src={Pranje} alt="Perionica Slika" className='pranjeauto' />
                        <div className='pranjenaziv'>Klasišno</div>
                    </div>
                    <div className='pranjeflex'>
                        <img src={Pranje2} alt="Perionica Slika" className='pranjeauto' />
                        <div className='pranjenaziv'>Dubinsko - Detaljno</div>
                    </div>
                    <div className='pranjeflex'>
                        <img src={Pranje3} alt="Perionica Slika" className='pranjeauto' />
                        <div className='pranjenaziv'>Tepih - Ostalo</div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <Footer />
            </div>
        </>
    )
}

export default Perionica