import React, { useEffect, useState } from 'react'
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { StrictModeDroppable } from '../../components/StrictModeDroppable';
import { Link } from 'react-router-dom';
import { API_URL } from '../../utils/api';
import { resizeAndConvertToWebp } from '../../utils/image';
import axios from 'axios';
import { ICategory, IItem } from '../../models/category';

const Categories = () => {
    const [categories, setCategories] = useState<ICategory[]>([]);
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [editData, setEditData] = useState<ICategory | null>(null);

    const [name, setName] = useState("");
    const [image, setImage] = useState<File | null>(null);

    function handleNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        setName(event.target.value);
    }

    function handleImageChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.files && event.target.files.length > 0) {
            setImage(event.target.files[0]);
        } else {
            setImage(null);
        }
    }

    async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        const formData = new FormData();
        formData.append('name', name);
        if (image) {
            const img = await resizeAndConvertToWebp(image, 1000, 1000)
            formData.append('image', img);
        }

        const endpoint = editData ? `${API_URL}/api/category/${editData.id}` : `${API_URL}/api/category`;

        axios.post(endpoint, formData, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then((res) => {
            console.log(res);
            setEditData(null);
            setName('');
            setImage(null);

            if (editData) {
                const newState = categories.map((obj: ICategory) => {
                    if (obj.id === res.data.id) {
                        return { ...obj, name: res.data.name, image: res.data.image };
                    }

                    return obj;
                });

                setCategories(newState);
            } else {
                setCategories((prevState) => {
                    return [...prevState, res.data];
                });
            }

        })
    }

    const handleCategoryDelete = (id: number) => {
        axios.delete(`${API_URL}/api/category/${id}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then((res) => {
            setCategories((prevState) => {
                return prevState.filter((cat: ICategory) => cat.id !== res.data.id)
            });
            // HENDLUJ DA IZBRISE IZ STATE KATEGORIJU
        })
    }

    const handleCategoryUpdate = (category: ICategory) => {
        setEditData(category);
        setName(category.name);
    }

    const handleFormReset = () => {
        setEditData(null);
        setName('');
        setImage(null);
    }

    useEffect(() => {
        setIsLoading(true);
        axios.get(`${API_URL}/api/category`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then((res) => {
            setCategories(res.data);
        }).catch((e) => {
            setError(e.message);
            setCategories([]);
        })
        setIsLoading(false);
    }, [])

    function handleDragEnd(result: any) {
        // Get the updated item list after drag and drop
        const updatedItems = Array.from(categories);
        const [reorderedItem] = updatedItems.splice(result.source.index, 1);
        updatedItems.splice(result.destination.index, 0, reorderedItem);

        // Get the list of item IDs and their indexes
        const updatedItemIds = updatedItems.map((item: ICategory) => item.id);
        const updatedItemIndexes = updatedItems.map((item, index) => index);

        // Do something with the updated items and their indexes
        setCategories(updatedItems);
        axios.post(`${API_URL}/api/category/update-index`, {
            updatedItemIndexes,
            updatedItemIds
        }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then((res) => {
            console.log(updatedItemIds);
            console.log(updatedItemIndexes);
        });
    }

    return (
        <div className='admin-categories'>
            <div className='admin-add-category'>
                <div className='admin-form-title'>{editData ? 'Izmeni izabranu kategoriju' : 'Dodaj kategoriju'}</div>
                <form className='admin-form' onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="name">Naziv:</label>
                        <input
                            type="text"
                            id="name"
                            value={name}
                            onChange={handleNameChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="image">Slika:</label>
                        <input
                            type="file"
                            id="image"
                            accept="image/*"
                            value={""}
                            onChange={handleImageChange}
                        />
                        {image && <div>Slika izabrana</div>}
                    </div>
                    <button className='admin-submit' type="submit">{editData ? 'Izmeni' : 'Dodaj'}</button>
                    {editData && <button className='admin-reset' onClick={handleFormReset}>Otkazi</button>}
                </form>
            </div>
            <div className='admin-title'> Lista svih kategorija </div>
            <DragDropContext onDragEnd={handleDragEnd}>
                <div className='category-selector'>
                    <StrictModeDroppable droppableId="my-droppable">
                        {(provided: any, snapshot: any) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {categories.map((category: ICategory, index: number) => {
                                    return (
                                        <Draggable key={category.id} draggableId={`id${category.id}`} index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <div className='admin-category'>
                                                        <Link to={`/admin/subcategories/${category.id}`}>
                                                            <div className="category"
                                                                style={{ fontSize: '2.5em', color: 'white', background: `rgb(0, 0, 0, 0.31) url(${API_URL}${category.image}) no-repeat local center / cover` }}>
                                                                <span>{category.name}</span>
                                                            </div>
                                                        </Link>

                                                        <div className='admin-buttons'>
                                                            <button className='admin-button admin-green' onClick={() => handleCategoryUpdate(category)}>Izmeni</button>
                                                            <button className='admin-button admin-red' onClick={() => handleCategoryDelete(category.id)}>Obrisi</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    )
                                })}
                            </div>
                        )}
                    </StrictModeDroppable>
                </div>
            </DragDropContext>
        </div>
    )
}

export default Categories